@import 'styles/_sass-includes.scss';

.heading__block {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    margin: auto;
}
.heading__block h3 {
    padding: 4px 8px !important;
    width: fit-content;
    font-family: 'luminaire_script', sans-serif !important;
    margin-bottom: -12px;
    font-size: 48px;
    font-weight: 400;
    z-index: 1;
}
.heading__content {
    padding: 24px 44px;
    z-index: 0;
    font-size: 16px;
    color: $textGrey;
    line-height: 118%;
    position: relative;
    text-align: center;
    display: none;
}

.heading__content:before {
    content: '';
    position: absolute;
    top: 5px;
    left: -70px;
    width: 100px;
    height: 100px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: +99;
}

.heading__content:after{
    content: '';
    position: absolute;
    top: 0px;
    right: -70px;
    width: 100px;
    height: 100px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: +99;
}
