@import '~styles/sass-includes';

.singleselector {
  display: inline-flex;
  align-items: center;
  border: 1px dotted var(--neutral-30);
  height: 50px;
  padding: 0 1.5rem;
  border-radius: 0px;
  position: relative;
  // media queries
  @include breakpoint($mobile-lg) {
    height: 62px;
    display: flex;
    width: 100%;
  }
}

.singleselector__label {
  position: absolute;
  font-size: 12px;
  top: 0;
  left: 15px;
  transform: translateY(-45%) translateZ(0px);
  background-color: var(--neutral-00);
  color: var(--neutral-70);
  text-transform: capitalize;
  padding: 0 4px;
}

.singleselector__hex {
  width: 15px;
  height: 15px;
  display: block;
  margin-right: 10px;
  border-radius: 50%;
  box-shadow: 0 0 4px 0 rgba($black, 0.4);
}
