@import 'styles/_sass-includes.scss';

.hero__container {
  position: relative;
}

.hero__inner {
  height: inherit;
  width: 100%;
  margin: auto;
  align-items: center;
  position: relative;
  overflow: hidden;
  z-index: 2;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  justify-content: stretch;
  background-size: cover;
  background-position: center;

  > * {
    z-index: 2;
  }

  .hero__sectioncontent {
    margin: auto;
  }

  .hero__copy {
    max-width: 50rem;
    margin: auto;
    font-size: 1.8rem;
    line-height: 2.8rem;
    text-align: center;

    .pr_btn {
      border-color: transparent;
      margin-top: 24px;
      font-size: 14px;
      padding: 8px 20px;
      display: inline-block;
    }

    button {
      background-color: #fff;
      border: 1px solid #000;
      margin-top: 3rem;
      text-transform: uppercase;
      font-size: 1.5rem;
      font-family: inherit;
      font-weight: 600;
      letter-spacing: .1rem;

      a {
        display: block;
        padding: 1.2rem 3rem;
        color: inherit;
      }
    }
  }
}

.hero__subtitle {
  display: block;
}

.hero__container {
  position: relative;
  background-color: $pdpBlack;
}

.hero__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  grid-area: overlay;
  z-index: 1;
}

.hero__featuredimg {
  grid-area: featuredimg;
}

.hero__copy {
  grid-area: text;
}

.hero__search {
  position: relative;
  width: 100%;
  max-width: 60rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  z-index: 3;
  // media queries
  @include breakpoint($mobile-lg) {
    width: 100%;
  }
}

.hero__searchinput {
  margin-top: 2rem;
  width: 100%;
  position: relative;

  input {
    font-size: 2rem;
    height: 6.2rem;
    width: 54.8rem;
    max-width: 100%;
    border-radius: 3.1rem;
    padding: 0 8rem 0 3rem;
    text-align: center;
    // media queries
    @include breakpoint($mobile-lg) {
      width: 100%;
      font-size: 1.4rem;
      height: 5rem;
    }
  }
}

.hero__inner .hero__copy .hero__searchinput button {
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 3.1rem;
  width: 6.2rem;
  height: 6.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.9rem;
  margin-top: 0;
  color: var(--pure-white);
  border:0;
  // media queries
  @include breakpoint($mobile-lg) {
    height: 5rem;
    width: 5rem;
    font-size: 2rem;
  }
}
