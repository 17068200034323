@import 'styles/_sass-includes.scss';

.listingpage.row {
  @include breakpoint($mobile-lg){
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.listingpage.content {
  background-color: $white;
  color: $black;

}

.listing.page-content {
  // overflow: hidden;

  .footer {
    // media queries
    @include breakpoint($mobile-lg) {
      padding-bottom: 60px;
    }
  }
}

.listing-content {
  @extend %clearfix;
  display: flex;
  position: relative;
  margin: 40px 0;
  // media queries
  
  @include breakpoint($tablet-md) {
    margin: 0;
    width: 100%;
    display: block;
  }
}

.sizeoptions__label {
  display: flex;
  justify-content: space-between;
  // media queries
  @include breakpoint($mobile-lg) {
    justify-content: flex-end;
  }
}

.private-listing {
  position: fixed;
  left: 50px;
  z-index: 10;
  bottom: 100px;
  // media queries
  @include breakpoint ($tablet-lg) {
    position: fixed;
    bottom: 0;
    left: 0;
    top: unset;
  }

  .pr_alert__container {
    // media queries
    @include breakpoint ($tablet-lg) {
      width: 100vw;
      border-radius: 0px;
    }
  }
}

.theme--dark {
  .variantselector__size {
    background-color: #333333;
  }

  .variantselector__item.is-active {
    .variantselector__size,
    .variantselector__product {
      border-color: $white;
    }
  }

  .product-detail-tabs .product-detail-tabs__content {
    color: #DDDDDD;
  }

  .accordion__title:hover,
  .accordion__title:hover .fa {
    color: $white;
  }

  a {
    color: $white;
  }

  .pr_btn {
    color: $black;
    background-color: $white;
  }

  .dropdown__menu {
    background-color: $black;

    button {
      color: inherit;
      &:hover {
        background-color: rgba($white, 0.15);
      }
    }
  }
}

.theme--light {
  .variantselector__size {
    background-color: transparent;
  }
  
  .product-detail-tabs .product-detail-tabs__content {
    color: #000;
  }

  .accordion__title:hover,
  .accordion__title:hover .fa {
    color: $black;
  }

  a {
    color: $black;
  }
}
