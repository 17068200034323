// media query mixins
@mixin breakpoint($value, $type: max-width) {
  @media only screen and ($type: $value) {
    @content
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}
}
