@import 'styles/_sass-includes.scss';

.footer {
  @extend %page-margins;
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 1.6rem;
  line-height: 2.5rem;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 3.7rem;
  padding-bottom: 3.7rem;
  border-top: solid 1px rgba($white, 0.1);
  @include breakpoint($mobile-lg) {
    align-items: center;
    text-align: center;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }
}

.footer__bottom {
  max-width: 136.3rem;
  margin: 0 auto;
  padding: 1.5rem 3em 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 8rem;
  flex-wrap: wrap;
  font-size: 1.2rem;
  @include breakpoint($mobile-lg) {
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 4rem;
  }
}

.footer__bottomleft {
  flex-wrap: wrap;
  // media queries
  @include breakpoint($mobile-lg) {
    margin-bottom: 4.8rem;
  }
}

.footer__readmore {
  font-weight: 600;
  padding: 0 0 2.5rem;
  display: block;
}

.footer__menu {
  // media queries
  @include breakpoint($tablet-lg) {
    margin-bottom: 2rem;
  }
  @include breakpoint($mobile-lg) {
    margin-top: 4rem;
  }

  h5 {
    margin-bottom: 1rem;
    @include breakpoint($mobile-lg) {
      display: none;
    }
  }

  a {
    font-size: 1.5rem;
    text-decoration: none;
    @include breakpoint($mobile-lg) {
      font-size: 1.6rem;
      margin-bottom: 8px;
    }
  }
}

.footer__copyright {
  @include breakpoint($mobile-lg) {
    align-items: center;
  }
}

.footer__newsletter {
  font-size: 1.6rem;
  flex: 1 1 0;

  form {
    display: flex;
    width: fit-content;
  }

  p {
    padding: 0.8rem 0 2rem;
    margin: 0;
  }

  input.email {
    width: 100%;
    border: 0.1rem solid $white;
    background: transparent;
    padding: 0 1rem;
    color: inherit;

    @include placeholder {
      color: inherit;
    }
  }

  .submit-button {
    font-size: 1.4rem;
    width: 9rem;
    border: 0.1rem solid;
    color: $white;
    padding: 0 1.5rem;
    appearance: none;
    border-left: 0;
    letter-spacing: 0.05rem;
    font-weight: bold;
  }
}

.footer__inner {
  max-width: 136.3rem;
  margin: 0 auto;
  padding: 1.5rem 3em 0;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  // grid-template-areas: 
  //   "hero about"
  //   "hero newsletter"
  //   "hero menu";
  justify-content: stretch;

  > div:not(:last-of-type) {
    margin-right: 5rem;
    @include breakpoint($mobile-lg) {
      margin-right: 0rem;
    }
  }
}

.footer__hero {
  grid-area: hero;
  padding-right: 5rem;
}

.footer__about {
  grid-area: about;
  max-width: 52rem;
  
  p { margin-bottom: 2rem; }

  .footer__about-text {
    font-size: 1.4rem;
  }
}

.footer__menu { grid-area: menu; }
.footer__newsletter { grid-area: newsletter; }

.footer__socialicons {
  ul {
    @include breakpoint($mobile-lg) {
      justify-content: center;
    }
  }

  a {
    font-size: 2rem;
    @include breakpoint($mobile-lg) {
      font-size: 3rem;
    }
  }
}

.footer__localization {
  display: flex;

  .mr2 {
    @include breakpoint($mobile-lg) {
      display: none;
    }
  }
}

.localizationcontrols {
  color: inherit;
  @include breakpoint($mobile-lg) {
    min-width: 30rem;
    border: none;
    margin-bottom: 2rem;
  }

  > span {
    @include breakpoint($mobile-lg) {
      width: 50%;
      border: 1px solid rgba($white, 0.5);
      padding: 10px 15px;
    }

    &:first-of-type {
      @include breakpoint($mobile-lg) {
        margin-right: 8px;
      }
    }

    .pr_icon {
      margin-left: 5px;
      @include breakpoint($mobile-lg) {
        margin-left: auto;
      }
    }
  }
}

.footer__copyright {
  display: flex;
  // media queries
  @include breakpoint($mobile-lg) {
    flex-direction: column;
  }

  a {
    text-decoration: none;
  }

  > ul {
    display: flex;
    opacity: 0.6;
  }
}

a.footer__teespring {
  text-decoration: none;
  display: flex;
  align-items: center;

  @include breakpoint($mobile-lg) {
    font-size: 1.4rem;
  }

  .teespring-logo {
    width: 90px;
    margin-left: .8rem;
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

.footer__bottom__inner {
  display: flex;
  justify-content: space-between;
  // media queries
  @include breakpoint($mobile-lg) {
    flex-direction: column-reverse;
  }
}

