@import 'styles/_sass-includes.scss';

.add__options {
  .pr_selectfield__wrapper .pr_selectfield__toggle {
    border-radius: 0px !important;
    font-family: $inter-font-family;
    margin-bottom: 0 !important;

    @include breakpoint($desktop-lg) {
      font-size: 14px;
    }
  
    @include breakpoint($mobile-lg) {
      margin-bottom: 16px;
      height: 62px;
    }
  }

  .pr_inputfield__label {
    font-family: $inter-font-family;
    font-weight: 500;
    font-size: 12px;
    color: var(--neutral-30)
  }

  .pr_selectfield{
    &.sizeoption {
      color: $black;
    }
  }
}
