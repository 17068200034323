@import 'styles/_sass-includes.scss';

.product-feed{
  padding: 1.5rem 3em 0;
  max-width: 136.3rem;
  margin: 0 auto;
  width: 100%;

  @include breakpoint($mobile-lg) {
    padding: 1.5rem 1.5rem 0;
  }

  .product-feed-skeleton-container {
    display: flex;
    gap: 20px;
    justify-content: center;
    width: 100%;
    height: 350px;
    flex-wrap: wrap;

    .product-tile-skeleton {
      width: calc(25% - 1.5rem);
      height: calc(80% - 1.5rem);

      @include breakpoint($mobile-lg) {
        width: 45%;
      }
    }
  }
}

.tile-heading {
  color: var(--neutral-100);
}